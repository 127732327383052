.PaginatedList {
  text-align: center;
}

.PaginatedList .list {
  margin: 20px 0;
}

.PaginatedList .interactions {
  margin: 20px;
}