.PaginatedListTableRenderer td {
    vertical-align: middle;
}

.PaginatedListTableRenderer table {
    text-align: left;
}

.PaginatedListTableRenderer table .action {
    text-align: right;
}