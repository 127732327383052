@import 'custom.scss';

.App {
  display: flex;
  flex-direction: column;
}

.App .navbar-brand {
  font-weight: bold;
}

.AppContent {
  justify-content: center;
  flex: 1;
}

html, body {
  height: 100%;
}

.page-header {
  background: $secondary;
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 40px;
  border-top-color: $blue;
  border-top-width: 2px;
  border-top-style: solid;
}

.page-header h1 {
  text-transform: uppercase;
  font-size: 1.5em;
}

.navbar-nav .nav-link {
  padding-top: 0;
  padding-bottom: 0;
  height: 50px;
  line-height: 50px;
}